import { render, staticRenderFns } from "./ReservationConfirm.vue?vue&type=template&id=52a1959a&scoped=true&"
import script from "./ReservationConfirm.vue?vue&type=script&lang=js&"
export * from "./ReservationConfirm.vue?vue&type=script&lang=js&"
import style0 from "./ReservationConfirm.vue?vue&type=style&index=0&id=52a1959a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52a1959a",
  null
  
)

export default component.exports